import { escapeRegExp } from "@js/utils/string";

import type { PostHashtag } from "../../types";

export const splitTextByHashtags = (
  text: string,
  hashtagNames: string[],
): string[] => {
  if (hashtagNames.length === 0) {
    return [text];
  }

  const hashtagPattern = hashtagNames
    .map((name) => `#${escapeRegExp(name)}`)
    .join("|");
  const hashtagRegExp = new RegExp(`(${hashtagPattern})`, "g");
  const textArray = text.split(hashtagRegExp);

  return textArray.filter((fragment) => fragment !== "");
};

export const parseTextWithHashtags = ({
  text,
  postHashtags,
}: {
  text: string;
  postHashtags: PostHashtag[];
}): Array<{ text: string; hashtag?: PostHashtag }> => {
  if (!postHashtags?.length) {
    return [];
  }

  const hashtagNames = postHashtags.map((hashtag) => hashtag.hashtag_name);

  const textArray = splitTextByHashtags(text, hashtagNames);
  const uniqueHashtagsIds = new Set<number>();
  return textArray.map((textFragment) => {
    const hashtagInText = postHashtags.find((hashtag) => {
      return textFragment === `#${hashtag.hashtag_name}`;
    });

    if (!hashtagInText || uniqueHashtagsIds.has(hashtagInText.id)) {
      return { text: textFragment };
    }

    uniqueHashtagsIds.add(hashtagInText.id);

    return {
      text: textFragment,
      hashtag: hashtagInText,
    };
  });
};
